import RightArrowIcon from "../Icons/RightArrowIcon";
import LoadingIcon from "../Icons/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Constants/routes";
import { SmbMigration } from "../../Reducers/userReducer";
import { useHelpers } from "../../../Utils/helpers";

import MigrationStatusDetails from "../MigrationStatusDetails";
import { MigrationStatus, SourceType } from "../Enums/CommonEnums";

export default function SMBSummarizedStatus(props: { migration: SmbMigration, onModalToggle: (ids: string[]) => void }) {
    const navigate = useNavigate();
    const migration = props.migration;

    const errorStatus = migration.currentStatus === MigrationStatus.ReuploadFiles || migration.currentStatus === MigrationStatus.Error;
    const newToHandle = migration.currentStatus === MigrationStatus.New || migration.currentStatus === MigrationStatus.FilesUploaded;
    const isOngoing = migration.currentStatus >= MigrationStatus.ReadyForMigration && migration.currentStatus < MigrationStatus.Done;

    const canModifyAdditionalInfo = (migration.currentStatus === MigrationStatus.ReuploadFiles || migration.currentStatus === MigrationStatus.Error) && migration.additionalInfoCount > 0 && migration.hasAdditionalInformationErrors;

    const helper = useHelpers();

    let statusIcon: any;
    let title: string;
    let header = "Att hantera (kräver åtgärd)";


    if (newToHandle) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>
        title = "En ny att hantera"
    }
    else if (canModifyAdditionalInfo) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>
        title = "Stoppad - Komplettering behövs"
    }
    else if (errorStatus) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-error"}></i>
        title = "Stoppad - Hanteras av Visma Spcs"
    }
    else if (isOngoing) {
        statusIcon = <LoadingIcon />
        title = "Hanteras just nu av Visma Spcs"
        header = "Pågående"
    }
    else {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-success"}></i>
        title = "Företag överfört"
        header = "Slutförd överföring"
    }

    return <div className="panel">
        <div className="container-padding">
            <div>
                <h2 className="font-weight-bold">{header}</h2>
                <h3 className="font-weight-bold">{migration.customerName} {migration.organizationNumber} </h3>
                <div
                    id={migration.currentStatus === MigrationStatus.Done ? "migCustomerSatisfaction" : ""}
                    className={`mb-8 rounded bg-primary shadow-30 container-padding`}
                >
                    <div className="d-flex justify-content-between">
                        <span className="d-flex flex-column">
                            <div className="d-flex flex-row">
                                {statusIcon}
                                <p className="pl-4 pt-4 font-family-ubuntu-semibold">{title}</p>
                            </div>
                            <div className="mt-8">
                                <span className="badge badge-info mt-2">{helper.getSourceType(migration.sourceType)}</span>
                            </div>
                        </span>
                        <span className="d-flex">
                            {migration.currentStatus <= MigrationStatus.ReuploadFiles && (
                                <span className="vismaicon vismaicon-delete cursorpointer" onClick={() => props.onModalToggle([migration.id])}></span>
                            )}
                        </span>
                    </div>
                    <MigrationStatusDetails migration={migration} />
                    {migration.sourceType === SourceType.CsvFiles && migration.currentStatus === MigrationStatus.Manual && <div className="alert alert-info">
                        <p>
                            Behöver du ladda upp fler filer? <a href="#" onClick={() => navigate(`${routes.csvImport}`)}>Gå till uppladdning</a>
                        </p>
                    </div>}
                    {(migration.currentStatus === MigrationStatus.Done || migration.currentStatus === MigrationStatus.OnboardingCompleted || canModifyAdditionalInfo) && (
                        <div className="alert alert-info" role="alert">
                            <a
                                onClick={() => {
                                    if (canModifyAdditionalInfo) {
                                        navigate(`${routes.additionalInfo(migration.id)}`);
                                    } else if (migration.currentStatus === MigrationStatus.Done) {
                                        navigate(`${routes.smbMigrationDetails(migration.sourceType.toString())}`);
                                    }
                                }}
                            >
                                {canModifyAdditionalInfo ? "Klicka här för att komplettera dina uppgifter" : "Klicka här för att se din logg"}
                            </a>
                        </div>
                    )}
                    {(newToHandle && migration.sourceType !== SourceType.CsvFiles) && (
                        <div className="alert alert-warning" role="alert">
                            <p>
                                Du behöver gå igenom startguiden för att komma igång.{" "}
                                <a
                                    onClick={() => {
                                        navigate(`${routes.startGuideSMB(migration.sourceType.toString())}`);
                                    }}
                                >
                                    Klicka här
                                </a>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
}