import { useNavigate } from 'react-router';
import { routes } from '../../Constants/routes';

export default function CSVImportPage() {
    const navigate = useNavigate();
    const startFileImportGuide = () => {
        navigate(routes.fileImportGuide)
    }

    return (
        <div className="d-flex text-center  justify-content-center">
            <div className="hero-card col-6 d-flex flex-column align-items-center">
                <div className="pt-32">
                    <h1>Välkommen till Visma Spcs Flytthjälp</h1>
                </div>
                <div style={{ maxWidth: "550px" }}>
                    <p>Här väljer du vilket program eller leverantör du byter från och laddar sedan enkelt upp dina registerfiler och så fixar vi resten!</p>

                    <h2>Vilka filer kan jag ladda upp?</h2>
                    <p>Det är kund-, artikel- och leverantörsregister vi kan flytta in till Visma eEkonomi och de vanligaste filformaten är .csv och .txt.</p>

                    <p>Du hittar mer information och instruktioner kring hur du exporterar ut rätt filer från just ditt program på sidan innan, här: <a href="https://vismaspcs.se/support/overforing-till-visma-eekonomi" target="_blank" >Flytta till Visma eEkonomi »</a></p>

                    <h2>När är det klart?</h2>
                    <p>Vi försöker alltid flytta in dina register inom 48 tim på vardagar och när det är klart skickar vi ett mejl till den e-postadress som du loggade in med här.</p>

                    <p>Om du har frågor kring din flytt är du välkommen att kontakta oss på <a href="mailto:dataflytt.spcs@visma.com">dataflytt.spcs@visma.com</a>.</p>
                </div>
                <div className="pb-16">
                    <button type="button" className="btn btn-primary btn-lg" onClick={startFileImportGuide}>Kom igång</button>
                </div>
            </div>
        </div>
    );
}

