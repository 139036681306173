export const modalTypes = {
    csvFileHandler : "csvFileHandler",
    editFile: "editFile",
    importCsvGuide: "importCsvGuide",
    internalFileUploadModal: "internalFileUploadModal",
    createNewMigrationModal: "createNewMigrationModal",
    commentsModal: "commentsModal",
    detailsLogModal: "detailsLogModal",
    customerConfirmationSendFiles: "customerConfirmationSendFiles",
    createTransferCollaborationsModal: "createTransferOfCollaborationsModal",
    confirmDeleteFileInternal: "confirmDeleteFileInternal",
}