import { ModalAlert } from "../Helpers/Modal";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { FileImportGuideModalProps } from "./FileImportGuide";

export function CustomerConfirmationModal({ migration }: FileImportGuideModalProps) {
    const migrationAction = useMigrationActionCreator();

    return <ModalAlert title="Bekräfta överföring av filer" mainButtonDisabled={false} mainButtonTitle={"Slutför"} onSave={() => migrationAction.startManualMigration(migration)}>
        <p>Filerna kommer att skickas till företaget <strong>{migration.customerName}</strong> med organisationsnummer <strong>{migration.organizationNumber}</strong></p>
    </ModalAlert>;
}
