import { useEffect, useState } from "react";
import { Competitor, CompetitorConfig, CompetitorMigration, setCompetitorConfig } from "../../Reducers/manualMigrationReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import DropdownButton from "../Helpers/DropdownButton";
import DragAndDrop from "../Helpers/DragAndDrop";
import { modalTypes } from "../../Constants/modalTypes";
import { onToggleModal } from "../../Reducers/modalReducer";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { MigrationStatus, SourceType } from "../Enums/CommonEnums";
import { CustomerConfirmationModal } from "./CustomerConfirmationModal";
import { customerTypes, customerTerms } from "../../Constants/customerConfig";

export type FileImportGuideModalProps = {
    migration: CompetitorMigration;
};

export function FileImportGuide() {
    const dispatch = useAppDispatch();
    const activeModal = useAppSelector((state) => state.modal.modal);
    const migrationAction = useMigrationActionCreator();
    const [step, setStep] = useState(1);
    const [error, setError] = useState<string | null>(null);
    const [isCustomerRegisterChecked, setIsCustomerRegisterChecked] = useState(false);
    const [isSupplierRegisterChecked, setIsSupplierRegisterChecked] = useState(false);
    const [isArticleRegisterChecked, setIsArticleRegisterChecked] = useState(false);
    const [competitorConfig, setCompetitorConfigState] = useState<CompetitorConfig>({
        id: "",
        name: "",
        sendCustomersAsPrivate: null,
        customTermsOfPayment: "",
        supplierTermsOfPayment: ""
    });

    const supplierIsCheckedNotCustomer = isSupplierRegisterChecked && !isCustomerRegisterChecked;
    const migration = useAppSelector(state => state.manualMigration.csvMigration);
    const competitors = useAppSelector(state => state.manualMigration.competitors);

    const [selectedCompetitor, setSelectedCompetitor] = useState<Competitor | null>(migration?.competitor || null);

    useEffect(() => {
        migrationAction.getOrCreateManualMigration(SourceType.CsvFiles)
        migrationAction.getCompetitors();
    }, []);

    if (!migration || !competitors)
        return null;

    const nextBtnText = () => {
        const btnText = step === 4 ? "Ladda upp" : "Nästa";
        return btnText;
    }

    const sendMigration = () => {
        dispatch(onToggleModal(modalTypes.customerConfirmationSendFiles));
    }

    const onSelect = (item: Competitor) => {
        setSelectedCompetitor(item);
    };

    const onSelectCustomerType = (item: CompetitorConfig) => {
        setCompetitorConfigState(prevState => {
            const newState = {
                ...prevState,
                sendCustomersAsPrivate: item.sendCustomersAsPrivate
            };
            return newState;
        });
    };

    const onSelectedCustomerTerms = (item: CompetitorConfig) => {
        setCompetitorConfigState(prevState => ({
            ...prevState,
            customTermsOfPayment: item.customTermsOfPayment
        }));
    };
    const onSelectedSupplierTerms = (item: CompetitorConfig) => {
        setCompetitorConfigState(prevState => ({
            ...prevState,
            supplierTermsOfPayment: item.supplierTermsOfPayment
        }));
    };

    const handleNext = () => {
        switch (step) {
            case 1:
                if (!selectedCompetitor)
                    setError("Val av program/leverantör är obligatoriskt.");
                else {
                    setCompetitorConfigState(prevState => ({
                        ...prevState,
                        id: selectedCompetitor.id,
                        name: selectedCompetitor.name
                    }));
                    setError(null);
                    setStep(step + 1);
                }
                break;
            case 2:
                if (!isCustomerRegisterChecked && !isSupplierRegisterChecked && !isArticleRegisterChecked)
                    setError("Minst en typ av register måste väljas.");
                else if (isCustomerRegisterChecked || isSupplierRegisterChecked) {
                    setError(null);
                    setStep(step + 1);
                } else {
                    setError(null);
                    setStep(step + 2);
                }
                break;
            case 3:
                if (isCustomerRegisterChecked && (!competitorConfig.customTermsOfPayment || (competitorConfig.sendCustomersAsPrivate === null))) 
                    setError("Alla fält är obligatoriska.");
                else if (isSupplierRegisterChecked && !competitorConfig.supplierTermsOfPayment) {
                    setError("Alla fält är obligatoriska.");
                }
                else {
                    if (isSupplierRegisterChecked && !isCustomerRegisterChecked) {
                        setCompetitorConfigState(prevState => ({
                            ...prevState,
                            sendCustomersAsPrivate: false,
                            customTermsOfPayment: ""
                        }));
                    }
                    setError(null);
                    setStep(step + 1);
                }
                break;
            case 4:
                dispatch(setCompetitorConfig(competitorConfig));
                sendMigration();
                break;
            default:
                setError(null);
                setStep(step + 1);
                break;
        }
    };

    const handleBack = () => {
        if (step > 1) {
            switch (step) {
                case 3:
                    setIsCustomerRegisterChecked(false);
                    setIsSupplierRegisterChecked(false);
                    setError(null);
                    setStep(step - 1);
                    break;
                case 4:
                    if (!isCustomerRegisterChecked || !isSupplierRegisterChecked) 
                        setStep(step - 2);
                    else 
                        setStep(step - 1);
                    break;
                default:
                    setError(null);
                    setStep(step - 1);
                    break;
            }
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        switch (id) {
            case "CustomerRegister":
                setIsCustomerRegisterChecked(checked);
                break;
            case "SupplierRegister": 
                setIsSupplierRegisterChecked(checked);
                break;
            case "ArticleRegister":
                setIsArticleRegisterChecked(checked);
                break;
            default:
                break;
        }

        if (checked) {
            setError(null);
        }
    };

    const renderStepper = () => {
        return (
            <div className="stepper stepper-sm" >
                <ul>
                    <li className={step === 1 ? "active" : step > 1 ? "passed" : ""}>
                        <span>
                            <span>Leverantör</span>
                        </span>
                    </li>
                    <li className={step === 2 ? "active" : step > 2 ? "passed" : ""}>
                        <span>
                            <span>Typ av register</span>
                        </span>
                    </li>
                    {(isCustomerRegisterChecked || isSupplierRegisterChecked) && (
                        <li className={step === 3 ? "active" : step > 3 ? "passed" : ""}>
                            <span>
                                <span>Villkor</span>
                            </span>
                        </li>
                    )}
                    <li className={step === 4 ? "active" : ""}>
                        <span>
                            <span>Ladda upp</span>
                        </span>
                    </li>
                </ul>
            </div>
        );
    };

    const renderCheckboxes = () => {
        return (
            <div className="d-flex flex-column pl-24">
                <div className="checkbox">
                    <input type="checkbox" id="CustomerRegister" onChange={handleCheckboxChange} />
                    <label htmlFor="CustomerRegister">Kundregister</label>
                </div>
                <div className="checkbox">
                    <input type="checkbox" id="SupplierRegister" onChange={handleCheckboxChange} />
                    <label htmlFor="SupplierRegister">Leverantörsregister</label>
                </div>
                <div className="checkbox">
                    <input type="checkbox" id="ArticleRegister" onChange={handleCheckboxChange} />
                    <label htmlFor="ArticleRegister">Artikelregister</label>
                </div>
            </div>
        );
    };
    const specialTerms = ["Kontant", "Kort", "Swish direktbetalning"];

    const getDisplayTerm = (term: string) => {
        return specialTerms.includes(term) ? term : `${term} dagar`;
    };
    const renderCustomerTerms = () => {
        return (
            <div className="d-flex align-items-center mt-0">
                <div className="d-flex flex-row ">
                    {isCustomerRegisterChecked && (
                        <>
                            <div className={`text-left mr-48`}>
                                <h3>Kundvillkor</h3>
                                <DropdownButton list={customerTerms} onSelect={onSelectedCustomerTerms}>
                                    <button type="button" className="btn w-100 dropdown-toggle">
                                        {competitorConfig.customTermsOfPayment ? getDisplayTerm(competitorConfig.customTermsOfPayment) : "Välj"}
                                    </button>
                                </DropdownButton>
                            </div>
                            <div className="text-left">
                                <h3>Kundtyp</h3>
                                <DropdownButton list={customerTypes} onSelect={onSelectCustomerType}>
                                    <button type="button" className="btn w-100 dropdown-toggle">
                                        {competitorConfig.sendCustomersAsPrivate === null ? "Välj" : (competitorConfig.sendCustomersAsPrivate ? "Privat" : "Företag")}
                                    </button>
                                </DropdownButton>
                            </div>
                        </>
                    )}
                    {isSupplierRegisterChecked && (
                        <div className={`d-flex flex-column ${isCustomerRegisterChecked ? "ml-48" : ""}`}>
                            <div className="text-left">
                                <h3>Leverantörsvillkor</h3>
                                <DropdownButton list={customerTerms} onSelect={onSelectedSupplierTerms}>
                                    <button type="button" className="btn w-100 dropdown-toggle">
                                        {competitorConfig.supplierTermsOfPayment ? getDisplayTerm(competitorConfig.supplierTermsOfPayment) : "Välj"}
                                    </button>
                                </DropdownButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderUploadFiles = () => {
        return (
            <div className='d-flex flex-column align-items-center'>
                <div className='w-100'>
                    <DragAndDrop disabled={!migration} migrationId={migration.id}>
                        <p>Dra hit filer eller klicka för att ladda upp</p>
                    </DragAndDrop>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex col-12 flex-column align-items-center">
            <div className="col-6 hero-card flex-column " style={{ minHeight: "488px", minWidth: "816px" }}>
                <div className="flex-grow-1">
                    <div className="pl-32 pr-32">
                        {renderStepper()}
                    </div>
                    {step === 1 && (
                        <div className='d-flex flex-column pt-64'>
                            <div className="text-center">
                                <h3>Välj program/leverantör</h3>
                                <DropdownButton list={competitors} onSelect={onSelect}>
                                    <button type="button" className="btn w-100 dropdown-toggle">
                                        {selectedCompetitor ? selectedCompetitor.name : "Välj program/leverantör"}
                                    </button>
                                </DropdownButton>
                                {error && <p className="text-error mt-16">{error}</p>}
                            </div>
                            {migration.status > MigrationStatus.New &&
                                <div className="d-flex align-items-center justify-content-center mb-0">
                                    <div className="alert alert-info alert-sm mt-64" role="alert" style={{ maxWidth: "60%" }}>
                                        Du har redan skickat filerna en gång. <br />Skicka bara in nya filer i samråd med supporten.
                                    </div>
                                </div>}
                        </div>
                    )}
                    {step === 2 && (
                        <div className="d-flex flex-column align-items-center pt-32 ">
                            <h3>Välj typ av register</h3>
                            {renderCheckboxes()}
                            {error && <p className="text-error">{error}</p>}
                        </div>
                    )}
                    {step === 3 && (
                        <div className="d-flex flex-column align-items-center pt-32 ">
                            {renderCustomerTerms()}
                            {error && <p className="text-error pt-12 text-center mb-0">{error}</p>}
                            <div className="alert alert-info alert-sm mt-48 mb-32 pl-48 pr-48" role="alert" style={{ maxWidth: "80%" }}>
                                I Visma eEkonomi behöver {supplierIsCheckedNotCustomer ? "detta" : "dessa"} fält vara {supplierIsCheckedNotCustomer ? "ifyllt" : "ifyllda"} - om {supplierIsCheckedNotCustomer ? "det" : "de"} saknar ett värde <br /> vid importen sätts ett standardvärde. Ange önskat standarvärde här. <br /><br />
                                {isCustomerRegisterChecked && !isSupplierRegisterChecked && (
                                    <>
                                        <span style={{ fontWeight: 600 }}>Kundvillkor</span> motsvarar betalningsvillkor för kundfakturor.
                                    </>
                                )}
                                {!isCustomerRegisterChecked && isSupplierRegisterChecked && (
                                    <>
                                        <span style={{ fontWeight: 600 }}>Leverantörsvillkor</span> motsvarar betalningsvillkor för leverantörsfakturor.
                                    </>
                                )}
                                {isCustomerRegisterChecked && isSupplierRegisterChecked && (
                                    <>
                                        <span style={{ fontWeight: 600 }}>Kundvillkor</span> motsvarar betalningsvillkor för kundfakturor. <br /><span style={{ fontWeight: 600 }}>Leverantörsvillkor</span> motsvarar betalningsvillkor för leverantörsfakturor.
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {step === 4 && (
                        <div className="d-flex flex-column align-items-center pb-32">
                            <h3>Ladda upp fil/filer</h3>
                            {renderUploadFiles()}
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-between align-items-end">
                    <div className="pb-6">
                        <button type="button" className="btn btn-secondary" onClick={handleBack} disabled={step === 1}>
                            Tillbaka
                        </button>
                    </div>
                    <div className="pb-6">
                        <button type="button" className="btn btn-primary" onClick={handleNext}>
                            {nextBtnText()}
                        </button>
                    </div>
                </div>
            </div>
            {activeModal === modalTypes.customerConfirmationSendFiles && <CustomerConfirmationModal migration={migration} />}

        </div>
    );
}

export default FileImportGuide;
