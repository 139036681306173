import { CompetitorConfig } from "../Reducers/manualMigrationReducer";

export const customerTypes: CompetitorConfig[] = [
    { id: "1", name: "Privat", sendCustomersAsPrivate: true, customTermsOfPayment: "", supplierTermsOfPayment: "" },
    { id: "2", name: "Företag", sendCustomersAsPrivate: false, customTermsOfPayment: "", supplierTermsOfPayment: "" },
];

export const customerTerms: CompetitorConfig[] = [
    { id: "1", name: "0 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "0", supplierTermsOfPayment: "0" },
    { id: "2", name: "7 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "7", supplierTermsOfPayment: "7" },
    { id: "3", name: "10 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "10", supplierTermsOfPayment: "10" },
    { id: "4", name: "14 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "14", supplierTermsOfPayment: "14" },
    { id: "5", name: "15 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "15", supplierTermsOfPayment: "15" },
    { id: "6", name: "20 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "20", supplierTermsOfPayment: "20" },
    { id: "7", name: "30 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "30", supplierTermsOfPayment: "30" },
    { id: "8", name: "45 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "45", supplierTermsOfPayment: "45" },
    { id: "9", name: "60 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "60", supplierTermsOfPayment: "60" },
    { id: "10", name: "90 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "90", supplierTermsOfPayment: "90" },
    { id: "11", name: "120 dagar", sendCustomersAsPrivate: false, customTermsOfPayment: "120", supplierTermsOfPayment: "120" },
    { id: "12", name: "Kontant", sendCustomersAsPrivate: false, customTermsOfPayment: "Kontant", supplierTermsOfPayment: "Kontant" },
    { id: "13", name: "Kort", sendCustomersAsPrivate: false, customTermsOfPayment: "Kort", supplierTermsOfPayment: "Kort" },
    { id: "14", name: "Swish direktbetalning", sendCustomersAsPrivate: false, customTermsOfPayment: "Swish direktbetalning", supplierTermsOfPayment: "Swish direktbetalning" }
];