import React, { useState } from 'react';
import { useAppSelector } from "../../hooks";
import { FileProgressPreview } from "./FileProgressPreview";
import { useFileActionCreator } from "../../Actions/FileActionCreator";

interface DragAndDropProps {
    disabled?: boolean;
    children?: React.ReactNode
    migrationId: string
}

export default function DragAndDrop( props: DragAndDropProps) {
    const [isDragging, setIsDragging] = useState(false);
    const filesState = useAppSelector((state) => state.fileUploadProgress);
    const fileActions = useFileActionCreator();

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
        uploadFiles(e.dataTransfer.files as FileList);
    };

    const uploadFiles = (files: FileList) => {
        const filesArray = Array.from(files);

        filesArray.forEach((file: File) => {
            fileActions.uploadFileSigned(props.migrationId, file);
        });
    }

    const fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        uploadFiles(e.target.files as FileList)
    };

    return <>
        <div
            className={`drop-area mb-0${isDragging ? ' highlight' : ''}${props.disabled ? ' disabled' : ''} mb-16`}
            id="drop-area-a"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={props.disabled ? {pointerEvents: "none"} : {}}
        >
            {filesState && filesState.progress.map((progress, index) =>
                <div key={index}>

                    <span className="font-weight-bold">{progress.file.name} </span>
                    <div className="progress mb-0">
                        <div className="progress-bar" role="progressbar" aria-valuenow={progress.percentDone}
                             aria-valuemin={0} aria-valuemax={100} style={{width: `${progress.percentDone}%`}}>
                        </div>
                    </div>
                    <span className="percentage">{progress.percentDone}%</span>

                </div>)}

            {props.children}
            <span className="spinner spinner-secondary-blue"></span>
            <input
                type="file"
                id="fileUpload"
                className="drop-area-input"
                aria-label="File Upload"
                tabIndex={0}
                multiple
                onChange={fileSelected}
            />

            <label
                htmlFor="fileUpload"
                className="drop-area-upload"
            >

                <span className="vismaicon vismaicon-dynamic vismaicon-upload"></span>
            </label>
        </div>
        <FileProgressPreview migrationId={props.migrationId}/>
    </>
};