import { MigrationStatus } from "../Components/Enums/CommonEnums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompetitorMigration {
    id: string,
    competitor: Competitor, //obsolete in backend remove when possible
    competitorConfig: CompetitorConfig,
    status: MigrationStatus,
    customerName: string,
    organizationNumber: string,
}

export interface Competitor {
    id: string,
    name: string
}

export interface CompetitorConfig {
    id: string,
    name: string,
    sendCustomersAsPrivate: boolean | null,
    customTermsOfPayment: string,
    supplierTermsOfPayment: string
}

interface ManualMigrationState {
    csvMigration: CompetitorMigration | null,
    competitors: Competitor[] | null, //obsolete in backend remove when possible
    competitorsConfig: CompetitorConfig[] | null
}

const initialState : ManualMigrationState = { csvMigration: null, competitors: null, competitorsConfig: null};

export const slice = createSlice({
    name: 'manualMigration',
    initialState,
    reducers: {
        storeCsvMigration: (state, action: PayloadAction<CompetitorMigration>) => {
            state.csvMigration = action.payload;
        },
        storeCompetitors: (state, action: PayloadAction<Competitor[]>) => {
            state.competitors = action.payload;
        },
        setCompetitor: (state, action: PayloadAction<Competitor>) => {
            state.csvMigration!.competitor = action.payload;
        },
        storeCompetitorsConfig: (state, action: PayloadAction<CompetitorConfig[]>) => {
            state.competitorsConfig = action.payload;
        },
        setCompetitorConfig: (state, action: PayloadAction<CompetitorConfig>) => {
            state.csvMigration!.competitorConfig = action.payload;
        }
    },
});

export const {
    storeCsvMigration,
    storeCompetitors,
    setCompetitor,
    storeCompetitorsConfig,
    setCompetitorConfig
} = slice.actions;

export default slice.reducer;